.partnerContainer {
  margin-bottom: -16% !important;
  // margin-top: -10rem;
}

.partner-content {
  background: rgba(21, 21, 30, 0.5);
  -webkit-backdrop-filter: blur(19px);
  backdrop-filter: blur(19px);
  padding-block: 50px 32px;
  h1 {
    font-size: 56px;
    font-weight: 800;
    line-height: 120%;
  }
  strong {
    color: var(--neutral-colors-100);
  }

  @media screen and (max-width: 768px) {
    padding-block: 36px 16px;
    h1 {
      font-size: 32px;
    }
  }
}

.ticker {
  white-space: nowrap;
  width: 100%;
}

.tickerItem {
  display: inline-block;
  padding: 0 2rem;
  animation: ticker 1800s linear infinite;
}

.tickerReverse {
  animation-direction: reverse;
}

@keyframes ticker {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.masked-dev {
  position: relative;
  overflow: hidden;
}

.masked-dev::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, #15151e 0%, rgba(21, 21, 30, 0) 100%);
  pointer-events: none;
  width: 33%;
  height: 100%;
  z-index: 2;
}

.masked-dev::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(270deg, #15151e 0%, rgba(21, 21, 30, 0) 100%);
  pointer-events: none;
  width: 33%;
  height: 100%;
  z-index: 2;
}
