.item {
  background: url(../assets/svg/project-card.svg) 0 0/100% no-repeat;
  padding: 4rem 1.5rem 2rem 2rem;
  width: 384px;
  height: 366px;
  // zoom: 0.95;
  transition: all 0.3s ease-in-out;

  > div {
    bottom: 2rem;
    justify-content: space-between;

    a {
      margin-right: 4rem;
    }
  }

  p {
    transition: all 0.3s ease-in-out;
  }

  span {
    padding: 12px 16px;
    border: 2px solid var(--neutral-colors-600, #4d4d63);
    border-radius: var(--border-radius, 0.75rem);
    transition: all 0.3s ease-in-out;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &.current {
    background: url(../assets/svg/project-hovered.svg) 0 0/100% no-repeat;

    span {
      border-color: var(--colors-main-colors-blue);
    }
    p {
      color: var(--neutral-colors-100);
    }
  }

  @media screen and (max-width: 440px) {
    zoom: 0.8;
  }
}

.pro-img {
  bottom: 8rem;
  transform-origin: center bottom; /* Important */
  -webkit-animation: glowing 2.5s infinite alternate;
  animation: glowing 2.5s infinite alternate;
  z-index: -1;
}

@-webkit-keyframes glowing {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes glowing {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}
