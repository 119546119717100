.hero {
  @media (max-width: 992px) {
  }
  h1 {
    color: var(--neutral-colors-100);
    text-align: center;
    font-size: 80px;
    font-weight: 800;
    line-height: 0.8; /* 96px */
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    span {
      background: linear-gradient(180deg, #15151e -13.79%, #fff 120.69%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    color: var(--neutral-colors-400);
    text-align: center;

    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
  }

  height: 115vh;
}

.heroSection {
  background: url(../assets/img/hero-bg.png) center/66% no-repeat;
}

$herobreakpoints: (
  "1700px": (
    min-height: 120vh,
  ),
  "1600px": (
    min-height: 125vh,
    background-size: 70%,
  ),
  "1400px": (
    background-size: 90%,
  ),
  "1200px": (
    background-size: 95%,
  ),
  "1090px": (
    background-size: 100%,
  ),
  "992px": (
    min-height: 109vh,
    background-position: center,
  ),
  "830px": (),
  "769px": (
    min-height: 114vh,
    background-size: 110%,
    margin-top: -4rem,
  ),
  "588px": (
    background-size: 250%,
  ),
  "400px": (),
);

@each $breakpoint, $styles in $herobreakpoints {
  @media (max-width: $breakpoint) {
    .heroSection {
      @each $key, $value in $styles {
        #{$key}: $value;
      }
    }
    @if $breakpoint == "1070px" {
      .heroSection h1 {
        font-size: 4rem !important;
      }
      .heroSection p {
        font-size: 1rem !important;
      }
    }
    @if $breakpoint == "992px" {
      .heroSection h1 {
        font-size: 3rem !important;
      }
      .heroSection p {
        font-size: 1rem !important;
      }
    }
    @if $breakpoint == "400px" {
      .heroSection h1 {
        font-size: 2.5rem !important;
      }
      .heroSection p {
        font-size: 0.825rem !important;
      }
    }
  }
}
