.news-img-box {
  border-radius: 20px;
  background: #22222f;
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  padding: 3rem 1.5rem;
  @media (min-width: 992px) {
    width: 14%;
    max-width: 180px;
  }


  @media (max-width: 992px) {
    width: 100%;
    max-width: 200px;
  }
}

.news-title {
  padding: 40px;
  background: var(--colors-main-colors-blue, #144ee3);
  background-image: url(../assets/svg/news-grid.svg);
  width: min-content;
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: 48px;
    line-height: 120%;
    font-weight: 700;
  }
  @media (max-width: 1200px) {
    padding: 30px 0%;
    h2 {
      font-size: 36px;
    }
  }

  @media (max-width: 992px) {
    padding: 40px;
    h2 {
      font-size: 36px;
    }
  }
}

.news-content {
  padding: 1rem 2rem;
  border-radius: 20px;
  border: 1px solid rgba(77, 77, 99, 0.05);
  background: #22222f;
  width: 100%;
  height: 155px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  gap: 0.5rem 1rem;


  @media (max-width: 500px) {
    zoom: 0.92;
    margin-right: auto;
    margin-left: auto;
  }
}

.mob-heirarechy {
  @media (max-width: 992px) {
    width: 137% !important;
    margin-left: -18.5% !important;
    margin-bottom: -118% !important;
  }
  width: 127% !important;
  margin-left: -13.5% !important;
  margin-bottom: -108% !important;

  @media (max-width: 500px) {
    width: 126% !important;
    margin-left: -13% !important;
    margin-bottom: -107% !important;
  }

  @media (max-width: 450px) {
    width: 116% !important;
    margin-left:  -8% !important;
    margin-bottom: -99% !important;
    
  }
}

@media (min-width: 1300px) {
  .high-gap {
    gap: 2.5rem !important;
  }
}

@media (min-width: 1700px) {
  .high-gap {
    gap: 3.5rem !important;
  }
}

.border-grad-news {
  padding: 1px; 
  width: calc(100% - 2px); 
  height: calc(100% - 2px);
  border-radius: 20px;
}

.border-grad-news.left {
  background: linear-gradient(to right, transparent, var(--neutral-colors-600));

}

.border-grad-news.right {
  background: linear-gradient(to left, transparent, var(--neutral-colors-600));  
}


$breakpoints: (
  "1500px": 0.9,
  "1400px": 0.82,
  "1200px": 0.9,
  "1150px": 0.8,
  "1018px": 0.75,
  "992px": 1,
);

@each $breakpoint, $zoom-level in $breakpoints {
  @media (max-width: $breakpoint) {
    .zoom-min {
      zoom: $zoom-level;
    }
  }
}

