.card {
  background-color: rgba(21, 21, 30, 0.5);
  backdrop-filter: blur(45px);

  cursor: pointer;
  .card-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
  }
  .card-body {
    padding: 2rem 1.5rem;
  }
}

.h1 {
  color: var(--neutral-colors-100);
  font-size: 56px;
  font-weight: 800;
  line-height: 120%;
}

@media screen and (max-width: 1200px) {
  .card-body {
    padding: 1.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .h1 {
    font-size: 40px;
  }
}

.blur {
  background-image: url(../assets/svg/blog-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  &:not(.withoutConstantAnimation) {
    animation: shimmer 2s infinite alternate;
  }
  &.withoutConstantAnimation {
    background-position: center 35%;
    animation: zoomOutBackground 2s ease-in-out forwards;
    background-size: 200%;
    @media (max-width: 500px) {
      background-position: center 25%;
    }
    .card {
      @media screen and (min-width: 768px) {
        height: 100vh;
      }
      @media (min-width: 1800px) {
        height: 120vh;
      }
    }
  }
}

@keyframes zoomOutBackground {
  to {
    background-size: 100%;
  }
}

@keyframes shimmer {
  0% {
    background-size: 150%;
  }

  100% {
    background-size: 100%;
  }
}

.blog-item:hover .card-title {
  background: var(--colors-main-colors-gradient-1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-item:hover img {
  transform: scale(1.15);
}

.blog-item {
  overflow: hidden;
}
