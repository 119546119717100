$mobile-breakpoint: 992px;

.card {
  .shape {
    background: radial-gradient(94.84% 95.93% at 47.31% 52.21%, #2b2b3b 0%, #15151e 100%);
    border-right: 1px solid var(--neutral-colors-600, #4d4d63);
    border-radius: 18px 0 0 18px;
    @media (max-width: $mobile-breakpoint) {
      border-radius: 18px 18px 0 0;
      border-right: none;
      border-bottom: 1px solid var(--neutral-colors-600, #4d4d63);
      padding-block: 4rem;
    }
  }
  .content {
    background: var(--neutral-colors-700);
    padding: 3rem 2rem;
    border-radius: 0 18px 18px 0;
    @media (max-width: $mobile-breakpoint) {
      border-radius: 0 0 18px 18px;
    }

    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 130%; /* 41.6px */
      margin-bottom: 6px;
      transition: all 0.3s ease-in-out;
    }

    h3 {
      font-size: 16px;
      font-weight: 500 !important;
      line-height: 150%;
      margin-bottom: 0.75rem;
    }
    p {
      color: var(--neutral-colors-500, #8f8fa5);
    }
  }

  &:hover {
    .content h2 {
      background: var(--colors-main-colors-gradient-1);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 50% 100%;
    }

    .images {
      img {
        transform: scale(1.05);
        &:not(:last-child) {
          transform: translateY(-0.75rem) scale(1.1);
        }
      }
    }
  }
}

.images {
  z-index: 1;

  img {
    transition: all 0.3s ease-in-out;
  }

  img:not(:first-child) {
    //   margin-bottom: -5rem;
    z-index: -1;
    left: 0;
    margin-top: 1rem;
  }

  img:not(:last-child) {
    margin-top: -1rem;
  }
}

.borderSection {
  border-radius: 18px;
  border: 1px solid var(--neutral-colors-600, #4d4d63);
}

@media (max-width: 768px) {
  .borderSection {
    padding: 2rem 0.5rem !important;
  }
}
